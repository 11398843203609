<template>
  <div class="invoice-view">
    <v-card flat v-if="isLoading">
      <v-card-text>
        <v-progress-linear height="10" :indeterminate="true">
        </v-progress-linear>
      </v-card-text>
    </v-card>
    <div v-else>
      <v-card flat>
        <PrintViewBar v-if="Customer" title="Statement" :id="Customer.id" type="statement" :showEmailBtn="showEmailBtn"
          :postfix="`view?key=${currentBusiness.code}&start=${this.start}&end=${this.end}`"
          :text="`Invoice from ${currentBusiness.name} click the link to view`" :name="currentBusiness.name"
          :email="Customer.email" :obj="Customer" />

        <v-container fluid>
          <v-card-text class="pb-0">
            <v-layout row wrap>
              <v-flex class="text-left text-capitalize">
                <div class="headline font-weight-bold">
                  {{ currentBusiness.name }}
                </div>
                <div class="font-weight-medium">
                  {{ currentBusiness.address }} {{ currentBusiness.city }},
                  <span class="text-uppercase">{{ currentBusiness.state }}
                  </span>
                  {{ currentBusiness.zip }}
                </div>
                <div class="font-weight-medium">
                  {{ currentBusiness.phoneNumber }}
                </div>
                <div class="font-weight-medium text-lowercase">
                  {{ currentBusiness.email }}
                </div>
                <div class="font-weight-medium text-lowercase">
                  {{ currentBusiness.website }}
                </div>

                <div class="mt-2 display-3 font-weight-black blue--text">
                  STATEMENT
                </div>
                <div class="font-weight-regular" v-if="Customer">
                  <!-- <div class="font-weight-medium">BILL TO</div> -->
                  <div>{{ Customer.name }}</div>
                  <div>{{ Customer.address }}</div>
                  <div v-if="Customer.city">
                    {{ Customer.city }},<span class="text-uppercase">{{ Customer.state }}
                    </span>
                    {{ Customer.zip }}
                  </div>
                </div>
              </v-flex>
              <v-spacer> </v-spacer>
              <v-flex shrink class="text-right">
                <div v-if="!$vuetify.breakpoint.xsOnly">
                  <img height="120" class="logo" :src="currentBusiness.imageUrl" alt="" />
                </div>
                <div class="font-weight-regular">
                  <v-layout column>
                    <v-flex class="mb-3">
                      <div class="text-right">
                        FROM : {{ this.start | moment("MM/DD/YYYY") }}
                      </div>
                      <div class="text-right">
                        TO : {{ this.end | moment("MM/DD/YYYY") }}
                      </div>
                    </v-flex>

                    <v-flex shrink v-if="Customer">
                      <div class="
                          headline
                          font-weight-medium
                          text-right
                          blue--text
                        ">
                        BALANCE
                      </div>
                      <div class="display-2 font-weight-light text-right">
                        {{ Customer.balance | currency }}
                      </div>
                      <br />
                    </v-flex>
                  </v-layout>
                </div>
              </v-flex>
            </v-layout>
          </v-card-text>
          <table class="center">
            <tbody class="report-container">
              <v-card-text class="pt-0">
                <v-data-table dense :headers="headers" :items-per-page="itemsPerPage" hide-default-footer
                  :items="Statement">
                  <template v-slot:item="{ item, index }">
                    <tr>
                      <td class="text-left" :class="{ 'red--text': item.type === 'Payment' }">
                        <span v-if="item.localId">
                          <a href="#" @click="view(item)">{{ item.localId }}</a>
                        </span>
                      </td>
                      <td class="text-left" :class="{ 'red--text': item.type === 'Payment' }">
                        <span v-if="item.date">{{
                          item.date | moment("MM/DD/YYYY")
                          }}</span>
                      </td>
                      <td class="text-right" :class="{ 'red--text': item.type === 'Payment' }">
                        <span v-if="item.type === 'Payment'" class="black--text">Invoice # {{ item.Invoice.localId }}
                          -</span>
                        <!-- <span v-else-if="item.type === 'Invoice'" ># {{ item.localId }} -</span> -->
                        {{ item.type }}
                      </td>
                      <td class="text-right" :class="{ 'red--text': item.type === 'Payment' }">
                        <span v-if="item.total">
                          <a href="#" @click="view(item)">{{ item.total | currency }}</a></span>
                        <span v-else>
                          <a href="#" @click="view(item)">{{ item.amount | currency }}</a></span>
                      </td>
                      <td class="text-right" :class="{
                        'green--text': remainingBalance[index] === 0,
                      }">
                        {{ remainingBalance[index] | currency }}
                      </td>
                    </tr>
                  </template>
                </v-data-table>
              </v-card-text>
            </tbody>
          </table>
          <div class="center">
            <v-card-text>
              <v-divider> </v-divider>
              <small>{{ date | moment("dddd Do MMMM, YYYY") }} - {{ date | moment("LT") }}</small>
            </v-card-text>
          </div>
        </v-container>
      </v-card>
    </div>
  </div>
</template>

<script>
import PrintViewBar from "@/components/PrintViewBar";

import invoiceService from "../service";
import Hashids from "hashids";
import { mapGetters, mapActions } from "vuex";
const hashids = new Hashids();

export default {
  data() {
    return {
      date: this.$moment(),
      showEmailBtn: false,
      isLoading: true,
      Statement: [],
      Business: null,
      balance: 0,
      start: null,
      end: null,
      Customer: null,
      headers: [
        {
          text: "ID",
          align: "left",
          value: "productDetail",
          sortable: false,
        },
        {
          text: "DATE",
          align: "left",
          value: "productDetail",
          sortable: false,
        },
        {
          text: "TYPE",
          align: "center",
          value: "qty",
          sortable: false,
        },
        {
          text: "AMOUNT",
          align: "right",
          value: "rate",
          sortable: false,
        },
        {
          text: "BALANCE",
          align: "right",
          value: "total",
          sortable: false,
        },
      ],
      totalQty: 0,
    };
  },
  computed: {
    ...mapGetters("global", ["currentBusiness"]),
    itemsPerPage() {
      return this.Statement.length;
    },
    remainingBalance() {
      let tempBalance = this.balance;
      return this.Statement.map((row) => {
        if (row.type === "Invoice") {
          return (tempBalance += row.total);
        } else if (row.type === "Payment") {
          return (tempBalance -= row.amount);
        } else {
          return (tempBalance = row.amount);
        }
      });
    },
  },
  created() {
    this.initUser();
    if (this.$route.meta.public) {
      this.loadPublicData();
    } else {
      this.loadData();
      if (!this.currentBusiness) this.initUser();
    }

    // this.initUser();
    // // console.log('this.$route.params', this.$route.meta)
  },
  components: { PrintViewBar },
  methods: {
    ...mapActions("global", ["initUser", "initBusiness"]),
    view(invoice) {
      const hashId = hashids.encode(invoice.id);
      let routeData = null;
      if (invoice.type === "Payment") {
        if (this.$route.meta.public) {
          routeData = this.$router.resolve({
            path: `/public/payment/${invoice.id}/view`,
          });
        } else {
          routeData = this.$router.resolve({
            path: `/global/payment/${invoice.id}/view`,
          });
        }
      } else {
        if (this.$route.meta.public) {
          routeData = this.$router.resolve({
            path: `/public/invoice/${hashId}/view`,
          });
        } else {
          routeData = this.$router.resolve({
            path: `/global/invoice/${hashId}/view`,
          });
        }
      }

      window.open(routeData.href, "newwindow", "width=850, height=1100");
    },
    async renderData(response) {
      // console.log('response', response)
      await Promise.all(
        response.data.statementListInvoice.map((row) => {
          row.type = "Invoice";
        })
      );

      await Promise.all(
        response.data.statementListPayment.map((row) => {
          // console.log('row', row)
          row.date = row.Payment.date;
          row.localId = row.Payment.localId;
          row.id = row.Payment.id;
          row.type = "Payment";
          delete row.Payment;
        })
      );

      this.Statement = response.data.statementListInvoice;
      this.Statement = this.Statement.concat(
        response.data.statementListPayment
      );

      this.Customer = response.data.customer;
      this.Business = response.data.business;
      this.balance =
        response.data.previousInvoiceTotal - response.data.previousPaymentTotal;

      this.Statement.unshift({
        date: this.start,
        type: "Previous Balance",
        amount: this.balance,
      });
      this.Statement.sort(function (a, b) {
        var dateA = new Date(a.date),
          dateB = new Date(b.date);
        return dateA - dateB;
      });
      this.$Progress.finish();
      // document.title = `Invoice # ${this.Invoice.localId}`;

      if (this.Customer.email) {
        this.showEmailBtn = true;
      }
      this.isLoading = false;
    },
    loadPublicData() {
      const id = hashids.decode(this.$route.params.id);
      this.start = this.$route.query.start;
      this.end = this.$route.query.end;
      const key = this.$route.query.key;

      return invoiceService
        .getPublicStatement(id, {
          start: this.start,
          end: this.end,
          key: key,
        })
        .then((response) => {
          this.initBusiness(response.business);
          this.renderData(response);

          this.isLoading = false;
        });
    },
    loadData() {
      const id = hashids.decode(this.$route.params.id);
      this.start = this.$route.query.start;
      this.end = this.$route.query.end;

      return invoiceService
        .getStatement(id, { start: this.start, end: this.end })
        .then((response) => {

          this.renderData(response);
        });
    },
  },
};
</script>

<style scoped>
@media print {
  container {
    height: 100%;
    margin: 0 !important;
    padding: 0 !important;
    overflow: hidden;
  }

  .no-print {
    display: none;
  }

  .detailBox {
    overflow: visible;
    height: 100%;
  }

  * {
    background: 0 0 !important;
    color: #000 !important;
    box-shadow: none !important;
    text-shadow: none !important;
  }
}

.paidImg {
  position: absolute;
  left: 40%;
  top: 10%;
}

.signature {
  margin-top: 100px;
}

.center {
  background-color: white;
  margin: auto;
  width: 100%;
}

/* @media print {
  .detailBox * {
    overflow: visible;
    height: 100%;
    page-break-before: always;
  }
}
.detailBox {
  overflow: auto;
  height: 600px;
} */
</style>
